import { Component } from "react"

class ChapterSelector extends Component {

    choose(e){
        var v = parseInt(e.target.innerText);
        if(v > 0) this.props.choose(v)
    }
    render(){
        if(this.props.book === undefined ) return null;

        var max = this.props.book.length, ch = [];
        for(var i = 0; i < max; i++) {
            var className = (i+1) === this.props.value ? 'selected' : '';
            ch.push(<div className={className}>{i+1}</div>);
        }
        return (
            <div id="chapterSelector" onClick={(e)=>this.choose(e)}>
                { ch }
            </div>
        );
    }
}


export default ChapterSelector