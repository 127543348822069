import { Component } from "react"

class VerseMatrix extends Component {
    choose(e) {
        var ref = e.target.parentElement.title;
        if(ref.length === 0) return;
        this.props.choose(ref);
    }
    heading(m) {
        var matrix = m.slice(0,10), col = [<th>Ref</th>, <th>Verse</th>], ref = "A".charCodeAt(0);
        matrix.forEach((v,i) => col.push(<th>{String.fromCharCode(i + ref) }</th>));
        return (<tr>{col}</tr>);
    }
    rows(m) {
        var matrix = m.slice(0,10); 
        return matrix.map((r,ri) => {
            var col = matrix.map(c => <td>{ c.ib - r.ib }</td>), ref = r.bk + '  ' + r.ch + ':' + r.vs;
            return (<tr title={ref}>
                <th>{String.fromCharCode(65+ri)}</th>
                <td>{ref}</td>
                {col}
            </tr>);
        });
    }
    render(){
        var s = this.props.state;
        if(s.matrix.length === 0 || s.pdone !== 1) return null;
        return (
            <div className="matrix">
            <table onClick={(e)=>this.choose(e)} >
                {this.heading(s.matrix)}
                {this.rows(s.matrix)}
            </table>
            </div>
        );
    }
}


export default VerseMatrix