import { Component } from "react"

class BookSelector extends Component {
    chooseBook(e){
        this.props.choose(e.target.innerText);
    }
    render(){
        return (
            <div id="bookSelector" onClick={(e)=>this.chooseBook(e)}>
                { this.props.books.map((book,i) => {
                    var className = book === this.props.value ? 'selected' : '';
                    return (<div key={i} className={className} >{book}</div>);
                })}
            </div>
        );
    }
}


export default BookSelector