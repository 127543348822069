import { Component } from 'react';
import './App.css';
import BookSelector from './BookSelector.js';
import ChapterSelector from './ChapterSelector.js';
import Chapter from './Chapter.js';
import VerseMatrix from './VerseMatrix.js';
import books from './Books.json';
import icnSearch from './icn/search_white_24dp.svg';
import icnAdd from './icn/add_circle_outline_white_24dp.svg';
import icnRem from './icn/remove_circle_outline_white_24dp.svg';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      pdone: 0,
      data: {},
      iBook: 'Genesis',
      iChapter: 1,
      iVerse: 1,
      showResults: false,
      results: [],
      matrix: [],
    };
    this.ivb = 1;
    this.icb = 1;
    this.ldi = 0;
    this.books = books;
  }
  componentDidMount(){
    this.loadBibleZip();
    window.addEventListener('popstate', ()=>this.gotoHash());
  }
  gotoHash(){
    var value = window.location.hash.substring(1);
    if(!value) return;
    this.search({keyCode:13,target:{value}}, parseInt(value));
  }
  autoScroll(){
    var verse = document.querySelector('.chapt div.selected').offsetTop;
    if(verse > 200) window.scrollTo(0, verse-200);
    else window.scrollTo(0,0);
  }
  loadBibleZip(){
    window.fetch("bible.png", { cache: "force-cache" })
      .then(r => r.arrayBuffer())
      .then(r => this.decompress(r, 'gzip'))
      .then(r => {
        this.setState({ data: JSON.parse(r), pdone: 1 });
        setTimeout(()=>this.gotoHash(), 500);
      });
  }
  decompress(byteArray, encoding) {
    const cs = new window.DecompressionStream(encoding);
    const writer = cs.writable.getWriter();
    writer.write(byteArray);
    writer.close();
    return new Response(cs.readable).arrayBuffer().then(function (arrayBuffer) {
      return new TextDecoder().decode(arrayBuffer);
    });
  }
  search(e, hash){
    var t = e.target.value, s = this.state, results = [], tokens = t.toUpperCase().split(" ");
    if(t.length === 0) return this.setState(o=>({...o, results}));
    if(e.keyCode !== 13) return;
    var isTs = isNaN(t.substring(0,2));

    var offset = isTs ? 0 : parseInt(t), cverse = s.data[s.iBook][s.iChapter][s.iVerse];
    var tv = hash || (cverse.ib + 31102 + offset) % 31102;
    if(tv === 0) tv = 31102;
    var sb = this.state.iBook, sc = s.iChapter, sv = s.iVerse;
    for(var i = 0; i < 31102; i++){
      var sBook = s.data[sb], sChap = sBook[sc], sVerse = sChap[sv], stxt = sVerse.text.toUpperCase();
      if(isTs) {
        if(tokens.every(n => stxt.includes(n)))
          results.push({bk: sb, ch: sc, vs: sv, txt: sVerse.text, ib: sVerse.ib })
      }
      else if(sVerse.ib === tv) {
        this.setState(o=>({...o, iBook: sb, iChapter: sc, iVerse: sv}));
        break;
      }
      if((sv + 1) > sChap.length) {
        sv = 1;
        if((sc + 1) > sBook.length) {
          sc = 1;
          if(sb === "Revelation") 
            sb = "Genesis";
          else 
            sb = this.books[this.books.findIndex(b => b === sb) + 1];
        } else sc += 1;
      }
      else sv += 1;
    }
    if(results.length > 0) this.setState(o=>({...o, results, showResults: true }));
  }
  pickResult(r){
    var ref = r.split("  "); 
    if(ref.length === 1) return;
    var iBook = ref[0], vref = ref[1].split(":"), 
      iChapter = parseInt(vref[0]), iVerse = parseInt(vref[1]);
    this.setState(o=>({...o, iBook, iChapter, iVerse}));
  }
  matrix(a){
    var s = this.state, cverse = s.data[s.iBook][s.iChapter][s.iVerse], matrix = s.matrix;
    var i = s.matrix.findIndex(w => w.ib >= cverse.ib);
    if(a === "+" && matrix.length < 10) {
      if(i > -1 && s.matrix[i].ib === cverse.ib) return;
      matrix = i === -1 ? [...s.matrix] : s.matrix.slice(0,i);
      matrix.push({ bk: s.iBook, ch: s.iChapter, vs: s.iVerse, ib: cverse.ib });
      if(i !== -1 && i < s.matrix.length) matrix.push(...s.matrix.slice(i));
    }
    if(a === "-") {
      if(i === -1 || s.matrix[i].ib !== cverse.ib) return;
      matrix = s.matrix.slice(0,i);
      if(i < s.matrix.length - 1) matrix.push(...s.matrix.slice(i+1));
    }
    this.setState(o=>({...o,matrix}));
  }
  render(){
    setTimeout(()=>this.autoScroll(), 200);
    window.cthis = this;
    var percent = this.state.pdone === 1 ? '' : '('+ this.state.pdone.toFixed(2).substring(2,4) + ' %)';
    return (
      <div className="App">
        <header className="App-header">
          <div className="">
            <img src={icnSearch} alt="search" title="Search by relative number or text" />
            <input type="text" onKeyUp={(e)=>this.search(e)} />
            <img src={icnAdd} alt="add" onClick={()=>this.matrix("+")} title="Add verse to matrix" />
            <img src={icnRem} alt="rem" onClick={()=>this.matrix("-")} title="Remove verse from matrix" />
            <div>KJV Bible Indexer { percent }</div>
          </div>
        </header>
        <VerseMatrix state={this.state} choose={(p)=>this.pickResult(p)} />
        <div className="App-body">
          <BookSelector 
            books={books}
            choose={(p)=>this.setState(s=>({...s, iBook: p, iChapter: 1, iVerse: 1 }))}
            value={this.state.iBook} />
          <ChapterSelector 
            choose={(p)=>this.setState(s=>({...s, iChapter: p, iVerse: 1 }))}
            book={this.state.data[this.state.iBook]}
            value={this.state.iChapter} />
          <Chapter
            choose={(p)=>this.setState(s=>({...s, iVerse: p}))}
            state={this.state}
            value={this.state.iVerse} />
          <div className="Results" onClick={(e)=>this.pickResult(e.target.innerHTML)}>
            { this.state.showResults ?
              this.state.results.map(r => <div>{r.bk + '  ' + r.ch + ':' + r.vs }</div>) : null }
          </div>
        </div>
      </div>
    );
  }
}

export default App;

