import { Component } from "react"

class Chapter extends Component {
    choose(e) {
        var v = parseInt(e.target.title);
        if(v) this.props.choose(v);
    }
    render(){
        var s = this.props.state;
        var book = s.data[s.iBook];
        if(book === undefined || s.pdone !== 1) return null;
        var chapter = book[s.iChapter], verses = [];
        
        for(var i = 1; i <= chapter.length; i++) {
            var v = chapter[i];
            var className = this.props.value === i ? "selected" : "";
            verses.push(<div className={className} title={i}>{'('+i+') '+ v.text }</div>);
            if(this.props.value === i) verses.push(<div className="info">{
                "rev verse: " + (chapter.length-i+1) +
                " bible verse: " + v.ib +
                " rev bible verse: " + (31102-v.ib+1) +
                " book verse: " + v.ik +
                " rev book verse: " + (book.vl-v.ik+1) +
                " book number: " + book.ic +
                " rev book number: " + (66-book.ic+1) +
                " bible chapter: " + chapter.icb +
                " rev bible chapter: " + (1189-chapter.icb+1)
            }</div>);
        }
        return (
            <div className="chapt" onClick={(e)=>this.choose(e)}>
                { verses }
            </div>
        );
    }
}


export default Chapter